import { FunctionComponent } from 'react';



const BuildingsLandmarksHouse:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6 object-cover" alt="" src="/buildings--landmarkshouse1-fill@2x.png" />);
};

export default BuildingsLandmarksHouse;
