import { FunctionComponent } from "react";

const LandingPage: FunctionComponent = () => {
  return (
    <div className="relative bg-base-white w-full flex flex-col items-end justify-start text-left text-mini text-background-primary-main font-text-m-medium">
      <div
        style={{
          width: "100%",
        }}
        className="w-device-desktop bg-background-dark-base-main overflow-hidden flex flex-col items-center justify-center relative text-text-icons-dark-base-main"
      >
        <div className="self-stretch flex flex-row items-center justify-between py-main-system-20 px-padding-desktop-main z-[3] border-b-[1px] border-solid border-stroke-dark-base">
          <div className="flex flex-row items-center justify-start gap-[64px]">
            <img
              className="w-[167.66px] relative h-[35.71px] overflow-hidden shrink-0"
              alt=""
              src="/capa-1.svg"
            />
            <div className="flex flex-row items-center justify-start gap-[32px]">
              <div className="rounded-roundness-inside-m overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Home
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="rounded-roundness-inside-m overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  About Us
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="flex flex-col items-end justify-start text-right">
                <div className="h-[22px] flex flex-row items-center justify-start gap-[4px]">
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                    Services
                  </div>
                  <img
                    className="w-5 relative h-5 overflow-hidden shrink-0"
                    alt=""
                    src="/arrowschevrondown.svg"
                  />
                </div>
              </div>
              <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                Portfolio
              </div>
              <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                Contact us
              </div>
              <div className="rounded-roundness-inside-m overflow-hidden flex flex-row items-center justify-center gap-[6px]">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Marketplace
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center justify-end text-text-icons-base-main">
            <div
              onClick={() => {
                window.open(
                  "https://main.d3bmg5a38n287h.amplifyapp.com",
                  "_blank"
                );
              }}
              style={{
                cursor: "pointer",
              }}
              className="rounded-roundness-inside-m bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] border-[1px] border-solid border-stroke-base"
            >
              <img
                className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                alt=""
              />
              <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                Invest Now
              </div>
              <img
                className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center pt-12 px-padding-desktop-main pb-20 gap-[64px] z-[2] text-sm text-base-white">
          <div className="w-[707px] flex flex-col items-start justify-center">
            <div className="self-stretch flex flex-col items-start justify-start gap-[32px]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[12px]">
                <div className="rounded-roundness-inside-l1 bg-background-primary-main flex flex-row items-center justify-start py-main-system-2 px-main-system-81">
                  <div className="relative leading-[20px] font-semibold">
                    MARKETPLACE
                  </div>
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[24px] text-[52px] text-text-icons-dark-base-main">
                  <div className="self-stretch relative tracking-[-0.02em] leading-[60px] font-medium">
                    Full-Service Design Build Development Company
                  </div>
                  <div className="self-stretch relative text-lg leading-[26px] text-base-white">
                    Carthage Development is proud to introduce Carthage
                    Marketplace, providing a direct avenue for investors to
                    engage in both commercial and residential real estate
                    properties
                  </div>
                </div>
              </div>
              <div className="rounded-roundness-inside-m1 bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-16 px-main-system-24 gap-[8px] text-mini">
                <img
                  className="w-5 relative h-5"
                  alt=""
                  src="/essentialsdouble-heart.svg"
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Invest Now
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/arrowsarrowright.svg"
                />
              </div>
            </div>
          </div>
          <div className="w-[419px] relative h-[520px]" />
        </div>
        <div
          style={{
            width: "100%",
          }}
          className="w-device-desktop absolute !m-[0] h-full top-[0%] right-[0%] bottom-[0%] left-[0%] overflow-hidden z-[1]"
        >
          <img
            className="absolute top-[calc(50%_-_960px)] left-[calc(50%_-_960px)] w-[1920px] h-[1920px] overflow-hidden opacity-[0.5]"
            alt=""
            src="/background-pattern.svg"
          />
          <div className="absolute h-[100.27%] w-full top-[-0.14%] right-[0%] bottom-[-0.14%] left-[0%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(29,_29,_30,_0.1)_32.2%,_rgba(24,_24,_24,_0.54)_83.25%)] [backdrop-filter:blur(1px)]" />
        </div>
        <img
          className="w-[1456px] absolute !m-[0] top-[-56px] left-[0px] h-[1088px] object-contain z-[0]"
          alt=""
          src="/christianvelitchkovmxz64b83h0unsplash-1@2x.png"
        />
      </div>
      <div className="self-stretch bg-purple-25 flex flex-col items-start justify-start py-[100px] px-40 text-center text-21xl text-text-icons-base-main">
        <div className="self-stretch flex flex-col items-center justify-start">
          <div className="self-stretch flex flex-col items-start justify-start gap-[60px]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[40px]">
              <div className="self-stretch relative tracking-[-0.01em] leading-[40px]">
                Welcome to Carthage Development's Innovative MarketplaceExplore 
              </div>
              <div className="self-stretch relative text-5xl leading-[120%] text-text-icons-base-second">
                Carthage Marketplace not only offers direct investment
                opportunitiesbut also ensures a seamless and accessible deal
                flow. Our platformprovides investors with easy access to a
                diverse range of real estate projects
              </div>
            </div>
            <div
              style={{
                display: "grid",
              }}
              className="self-stretch flex flex-row items-start justify-start py-0 px-10 gap-[60px] text-left text-base"
            >
              <div className="flex-1 flex flex-row items-start justify-start gap-[12px]">
                <div className="flex flex-row items-center justify-center py-0.5 px-0">
                  <img
                    className="w-5 relative rounded-roundness-inside-l1 h-5"
                    alt=""
                    src="/checkbox.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                  <div className="self-stretch relative leading-[24px]">
                    Carthage Development specializes in crafting new builds for
                    both commercial and residential properties. With a proven
                    track recordof excellence in construction and development,
                    we bring unparalleled expertise to the real estate market.
                  </div>
                  <div className="w-[137px] relative text-sm leading-[20px] text-text-icons-base-second hidden h-6 shrink-0">
                    Supporting Text
                  </div>
                </div>
              </div>
              <div className="flex-1 flex flex-row items-start justify-start gap-[12px]">
                <div className="flex flex-row items-center justify-center py-0.5 px-0">
                  <img
                    className="w-5 relative rounded-roundness-inside-l1 h-5"
                    alt=""
                    src="/checkbox.svg"
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                  <div className="self-stretch relative leading-[24px]">
                    Say goodbye to intermediaries and third-party fundraisers.
                    Carthage Development is proud tointroduce Carthage
                    Marketplace, providing a direct avenue for investors to
                    engage with the GeneralPartner. Protect and maximize your
                    stake by investing seamlessly in our projects, ensuring a
                    moreefficient and rewarding real estate investment
                    experience.
                  </div>
                  <div className="w-[137px] relative text-sm leading-[20px] text-text-icons-base-second hidden h-6 shrink-0">
                    Supporting Text
                  </div>
                </div>
              </div>
              <div className="w-[169px] hidden flex-row items-start justify-start gap-[12px]">
                <div className="flex flex-row items-center justify-center py-0.5 px-0">
                  <img
                    className="w-5 relative rounded-roundness-inside-l1 h-5"
                    alt=""
                  />
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[2px]">
                  <div className="self-stretch relative leading-[24px] font-medium">
                    Designated customer success team
                  </div>
                  <div className="w-[137px] relative text-sm leading-[20px] text-text-icons-base-second hidden h-6 shrink-0">
                    Supporting Text
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
        className="w-device-desktop bg-primary-700 flex flex-col items-center justify-center py-16 px-[110px] box-border gap-[64px] text-sm"
      >
        <div className="w-[1216px] hidden flex-col items-center justify-center max-w-[1280px]">
          <div className="w-[800px] flex flex-col items-center justify-center gap-[8px]">
            <div className="w-[148px] relative leading-[20px] font-semibold hidden">
              1% OF THE INDUSTRY
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-13xl text-text-icons-base-main">
              <b className="self-stretch relative tracking-[-0.01em] leading-[40px]">
                Our Latest Features
              </b>
              <div className="self-stretch relative text-lg leading-[26px] text-text-icons-base-second whitespace-pre-wrap">
                Keeping your eye on the ball while performing a deep dive on the
                start-up mentality to derive convergence on cross-platform
                integration.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-start gap-[26px] text-gray-700">
          <div className="w-[520px] rounded-[9px] overflow-hidden shrink-0 flex flex-col items-center justify-center p-10 box-border gap-[32px] bg-[url('/public/lr-image@3x.png')] bg-cover bg-no-repeat bg-[top]">
            <div className="self-stretch shadow-[0px_0px_1px_rgba(113,_128,_150,_0.04),_0px_4px_8px_rgba(113,_128,_150,_0.08)] flex flex-col items-start justify-start gap-[16px]">
              <div className="w-[440px] rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border overflow-hidden hidden flex-col items-start justify-start py-4 px-5 gap-[16px] border-[1px] border-solid border-stroke-base">
                <div className="self-stretch flex flex-row items-center justify-between text-base text-text-icons-base-main">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[24px] font-semibold">
                        Erica Harris
                      </div>
                      <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-text-icons-base-second">
                        Tactics Director
                      </div>
                    </div>
                  </div>
                  <div className="relative text-lg leading-[28px] font-semibold text-text-icons-success">
                    32%
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
              </div>
              <div className="self-stretch rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-col items-start justify-start py-4 px-5 gap-[16px] border-[1px] border-solid border-stroke-base">
                <div className="self-stretch flex flex-row items-center justify-between text-base text-primary-700">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl bg-primary-700 h-10 flex flex-row items-center justify-center relative">
                      <img
                        className="w-6 relative h-6 overflow-hidden shrink-0 z-[0]"
                        alt=""
                        src="/interactionsminimize.svg"
                      />
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[1]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[24px] font-medium">
                        Investment Fund
                      </div>
                    </div>
                  </div>
                  <div className="rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-mini text-background-primary-main">
                    <img
                      className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                      alt=""
                    />
                    <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                      View Offering
                    </div>
                    <img
                      className="w-5 relative h-5 overflow-hidden shrink-0"
                      alt=""
                      src="/arrowschevronright.svg"
                    />
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
                <div className="w-[300px] hidden flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[8px]">
                    <div className="w-10 rounded-31xl h-10 flex flex-row items-center justify-center relative bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium">
                        Lookscout
                      </div>
                      <div className="relative tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                        Value
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-end justify-start text-smi text-success-700">
                    <div className="rounded-roundness-inside-m bg-success-50 flex flex-row items-center justify-start py-0.5 px-2">
                      <div className="relative tracking-[-0.1px] leading-[18px] font-medium">
                        Label
                      </div>
                    </div>
                    <div className="relative text-sm tracking-[-0.1px] leading-[20px] font-medium text-gray-50">
                      $3200.50
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-[440px] shadow-[0px_0px_1px_rgba(113,_128,_150,_0.04),_0px_4px_8px_rgba(113,_128,_150,_0.08)] h-[150.5px] flex flex-row items-start justify-start gap-[16px] text-text-icons-base-main">
              <div className="w-[212px] rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border overflow-hidden shrink-0 hidden flex-col items-center justify-start p-4 gap-[12px] border-[0.6px] border-solid border-stroke-base">
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[10.124999046325684px]">
                    <div className="w-[40.5px] rounded-[31.64px] h-[40.5px] flex flex-row items-center justify-center relative bg-[url('/public/avatar2@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-[8.86px] absolute !m-[0] top-[17.72px] left-[17.72px] h-[8.86px] hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative leading-[20px] font-medium">
                        Slack
                      </div>
                      <div className="w-[88px] relative text-[8.86px] tracking-[-0.06px] leading-[12.66px] font-medium text-gray-50 hidden">
                        30+resources finded
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-[20.25px] relative h-[20.25px] overflow-hidden shrink-0"
                    alt=""
                  />
                </div>
                <div className="flex flex-col items-start justify-end gap-[8px] text-9xl">
                  <b className="w-[188px] relative tracking-[-0.01em] leading-[36px] inline-block">
                    $852.00
                  </b>
                  <div className="w-[188px] flex flex-col items-start justify-start text-smi">
                    <div className="self-stretch flex flex-row items-center justify-start">
                      <div className="rounded-[34.17px] bg-neutral-400 flex flex-row items-center justify-start py-0.5 px-2.5">
                        <div className="relative leading-[18px] font-medium">
                          Offline
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[212px] rounded-3xs bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border overflow-hidden shrink-0 hidden flex-col items-center justify-start p-4 gap-[12px] border-[0.6px] border-solid border-stroke-base">
                <div className="self-stretch flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center justify-start gap-[10.124999046325684px]">
                    <div className="w-[40.5px] rounded-[31.64px] h-[40.5px] flex flex-row items-center justify-center relative bg-[url('/public/avatar3@3x.png')] bg-cover bg-no-repeat bg-[top]">
                      <img
                        className="w-[8.86px] absolute !m-[0] top-[17.72px] left-[17.72px] h-[8.86px] hidden z-[0]"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col items-start justify-start">
                      <div className="relative leading-[20px] font-medium">
                        Slack
                      </div>
                      <div className="w-[88px] relative text-[8.86px] tracking-[-0.06px] leading-[12.66px] font-medium text-gray-50 hidden">
                        30+resources finded
                      </div>
                    </div>
                  </div>
                  <img
                    className="w-[20.25px] relative h-[20.25px] overflow-hidden shrink-0"
                    alt=""
                  />
                </div>
                <div className="flex flex-col items-start justify-end gap-[8px] text-9xl">
                  <b className="w-[188px] relative tracking-[-0.01em] leading-[36px] inline-block">
                    $852.00
                  </b>
                  <div className="w-[188px] flex flex-col items-start justify-start text-smi">
                    <div className="self-stretch flex flex-row items-center justify-start">
                      <div className="rounded-[34.17px] bg-neutral-400 flex flex-row items-center justify-start py-0.5 px-2.5">
                        <div className="relative leading-[18px] font-medium">
                          Offline
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-start text-center text-5xl text-base-white">
            <div className="self-stretch h-[252px] flex flex-col items-center justify-center">
              <div className="self-stretch flex flex-row items-start justify-between">
                <div className="flex flex-col items-center justify-start gap-[16px] min-w-[240px]">
                  <div className="rounded-roundness-inside-l1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-row items-center justify-center p-[10.285714149475098px] border-[0.9px] border-solid border-stroke-base">
                    <img
                      className="w-[27.43px] relative h-[27.43px] overflow-hidden shrink-0"
                      alt=""
                      src="/icon.svg"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
                    <div className="self-stretch relative tracking-[-0.01em] leading-[30px] font-medium">
                      Commercial
                    </div>
                    <div className="w-[170px] relative text-base leading-[110%] text-primary-50 inline-block h-[105px] shrink-0">
                      Our focus is on multifamily and commercial rental
                      properties.
                    </div>
                    <div className="rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-left text-mini text-background-primary-main">
                      <img
                        className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                        alt=""
                      />
                      <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                        View Offering
                      </div>
                      <img
                        className="w-5 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src="/arrowschevronright.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start gap-[16px] min-w-[240px]">
                  <div className="rounded-roundness-inside-l1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-row items-center justify-center p-[10.285714149475098px] border-[0.9px] border-solid border-stroke-base">
                    <img
                      className="w-[27.43px] relative h-[27.43px] overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/icon@2x.png"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
                    <div className="self-stretch relative tracking-[-0.01em] leading-[30px] font-medium">
                      Residential
                    </div>
                    <div className="w-[170px] relative text-base leading-[110%] text-primary-50 inline-block h-[104px] shrink-0">
                      Our focus is primarily on residential new builds ranging
                      in the 750k-950 k price points.
                    </div>
                    <div className="rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-left text-mini text-background-primary-main">
                      <img
                        className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                        alt=""
                      />
                      <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                        View Offering
                      </div>
                      <img
                        className="w-5 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src="/arrowschevronright.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-start gap-[16px] min-w-[240px]">
                  <div className="rounded-roundness-inside-l1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] flex flex-row items-center justify-center p-[10.285714149475098px] border-[0.9px] border-solid border-stroke-base">
                    <img
                      className="w-[27.43px] relative h-[27.43px] overflow-hidden shrink-0 object-cover"
                      alt=""
                      src="/icon@2x.png"
                    />
                  </div>
                  <div className="self-stretch flex flex-col items-center justify-start gap-[16px]">
                    <div className="self-stretch relative tracking-[-0.01em] leading-[30px] font-medium">
                      Mix-Use Development
                    </div>
                    <div className="w-[170px] relative text-base leading-[110%] text-primary-50 inline-block">
                      Our focus is a combination of commercial plaza with
                      townhomes. 
                    </div>
                    <div className="rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-left text-mini text-background-primary-main">
                      <img
                        className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                        alt=""
                      />
                      <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                        View Offering
                      </div>
                      <img
                        className="w-5 relative h-5 overflow-hidden shrink-0"
                        alt=""
                        src="/arrowschevronright.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden hidden flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-left text-mini">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Start now
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-[1280px] hidden flex-row items-center justify-center py-0 px-8 box-border gap-[16px] text-mini text-base-white">
          <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px]">
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
              View All
            </div>
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
          </div>
          <div className="rounded-roundness-inside-m bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden hidden flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-text-icons-base-main border-[1px] border-solid border-stroke-base">
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
              Button
            </div>
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
        className="w-device-desktop bg-base-white flex flex-col items-center justify-center py-16 px-padding-desktop-main box-border gap-[64px] text-sm"
      >
        <div className="w-[1216px] hidden flex-col items-center justify-center max-w-[1280px]">
          <div className="w-[800px] flex flex-col items-center justify-center gap-[8px]">
            <div className="w-[148px] relative leading-[20px] font-semibold hidden">
              1% OF THE INDUSTRY
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-13xl text-text-icons-base-main">
              <b className="self-stretch relative tracking-[-0.01em] leading-[40px]">
                Easier Work Organization
              </b>
              <div className="self-stretch relative text-lg leading-[26px] text-text-icons-base-second">
                Here’s a quick overview of our main features and what we have to
                offer. For more in-depth reviews, browse around our website and
                articles.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch relative text-21xl tracking-[-0.01em] leading-[40px] text-text-icons-base-main text-center">
          Explore Funds and Upcoming Projects
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[32px] text-smi text-color-neutral-element-primary">
          <div className="flex-1 rounded-xl flex flex-col items-start justify-start border-[1px] border-solid border-stroke-base">
            <div className="self-stretch relative rounded-xl h-[188.81px] overflow-hidden shrink-0 bg-[url('/public/img@3x.png')] bg-cover bg-no-repeat bg-[top]">
              <div className="absolute top-[11.19px] left-[168px] rounded-roundness-inside-l bg-warning-300 flex flex-col items-start justify-start py-main-system-4 px-main-system-10">
                <div className="relative leading-[18px] font-medium">
                  Most viewed
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start p-4 gap-[16px] text-sm text-background-primary-main">
              <div className="self-stretch h-[151px] flex flex-col items-start justify-start gap-[10px]">
                <div className="self-stretch rounded-roundness-inside-m1 overflow-hidden flex flex-row items-center justify-start gap-[6px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/essentialscheckbox.svg"
                  />
                  <div className="relative tracking-[0.1px] leading-[20px] font-semibold">
                    Private investment fund
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <b className="self-stretch relative text-xl tracking-[-0.01em] leading-[28px] text-color-neutral-element-primary">
                  West Little York P.E.F 2024 Opportunity 
                </b>
                <div className="self-stretch relative text-mini leading-[22px] font-medium text-text-icons-base-second [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
                  Multifamily 100 Unit Apartment Class B $14M Project. 
                </div>
              </div>
              <div className="self-stretch rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-mini">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Invest Now
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/arrowschevronright.svg"
                />
              </div>
            </div>
          </div>
          <div className="flex-1 rounded-xl flex flex-col items-start justify-start text-sm text-background-primary-main border-[1px] border-solid border-stroke-base">
            <img
              className="self-stretch relative rounded-xl max-w-full overflow-hidden h-[188.81px] shrink-0 object-cover"
              alt=""
              src="/img@3x.png"
            />
            <div className="self-stretch flex flex-col items-start justify-start p-4 gap-[16px]">
              <div className="self-stretch h-[151px] flex flex-col items-start justify-start gap-[10px]">
                <div className="self-stretch rounded-roundness-inside-m1 overflow-hidden flex flex-row items-center justify-start gap-[6px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/essentialscheckbox.svg"
                  />
                  <div className="relative tracking-[0.1px] leading-[20px] font-semibold">
                    Private investment fund
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <b className="self-stretch relative text-xl tracking-[-0.01em] leading-[28px] text-color-neutral-element-primary">
                  290 and 8 P.E.F 2024
                </b>
                <div className="self-stretch relative text-mini leading-[22px] font-medium text-text-icons-base-second [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:3] [-webkit-box-orient:vertical]">
                  Industrial Mini Park for Industrial Real Estate Use. It is a
                  6M Project 
                </div>
              </div>
              <div className="self-stretch rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-mini">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Invest Now
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/arrowschevronright.svg"
                />
              </div>
            </div>
          </div>
          <div className="flex-1 rounded-xl flex flex-col items-start justify-start border-[1px] border-solid border-stroke-base">
            <div className="self-stretch relative rounded-xl h-[188.81px] overflow-hidden shrink-0 bg-[url('/public/img@3x.png')] bg-cover bg-no-repeat bg-[top]">
              <div className="absolute top-[10.19px] left-[199px] rounded-roundness-inside-l bg-success-300 flex flex-col items-start justify-start py-main-system-4 px-main-system-10">
                <div className="relative leading-[18px] font-medium">
                  Updated
                </div>
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start p-4 gap-[16px] text-sm text-background-primary-main">
              <div className="self-stretch h-[150px] flex flex-col items-start justify-start gap-[10px]">
                <div className="self-stretch rounded-roundness-inside-m1 overflow-hidden flex flex-row items-center justify-start gap-[6px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0"
                    alt=""
                    src="/essentialscheckbox.svg"
                  />
                  <div className="relative tracking-[0.1px] leading-[20px] font-semibold">
                    Private investment fund
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
                <b className="self-stretch relative text-xl tracking-[-0.01em] leading-[28px] text-color-neutral-element-primary">
                  Opportunity Fund 2025
                </b>
                <div className="self-stretch relative text-mini leading-[22px] font-medium text-text-icons-base-second [display:-webkit-inline-box] overflow-hidden text-ellipsis [-webkit-line-clamp:5] [-webkit-box-orient:vertical]">
                  This opportunity fund is a debt fund that strictly focuses
                  onpurchasing highly valued property for commercialdevelopment 
                </div>
              </div>
              <div className="self-stretch rounded-roundness-inside-m1 bg-background-primary-second overflow-hidden flex flex-row items-center justify-center py-main-system-121 px-main-system-201 gap-[6px] text-mini">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Invest Now
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0"
                  alt=""
                  src="/arrowschevronright.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-[16px] text-mini text-base-white">
          <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px]">
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
              View more
            </div>
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
          </div>
          <div className="rounded-roundness-inside-m bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden hidden flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-text-icons-base-main border-[1px] border-solid border-stroke-base">
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
              Button
            </div>
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
        className="w-device-desktop bg-base-white flex flex-col items-center justify-center py-16 px-padding-desktop-main box-border gap-[64px]"
      >
        <div className="w-[1216px] hidden flex-col items-center justify-center max-w-[1280px] text-sm">
          <div className="w-[800px] flex flex-col items-center justify-center gap-[8px]">
            <div className="w-[148px] relative leading-[20px] font-semibold hidden">
              1% OF THE INDUSTRY
            </div>
            <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-13xl text-text-icons-base-main">
              <b className="self-stretch relative tracking-[-0.01em] leading-[40px]">
                Proud to Be Used By
              </b>
              <div className="self-stretch relative text-lg leading-[26px] text-text-icons-base-second">
                We’re proud to be used by the biggest and best players in the
                game. Here are only a couple of the companies we’re proud to
                call our clients.
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[32px] text-center text-text-icons-base-second">
          <div className="flex-1 rounded-roundness-outside-m bg-background-base-second flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-[28.81px] relative h-8"
              alt=""
              src="/vector.svg"
            />
            <div className="self-stretch relative leading-[22px] font-medium">
              Development Revolutionizing real estate with Carthage
              Marketplace's direct investment portal.
            </div>
          </div>
          <div className="flex-1 rounded-roundness-outside-m bg-background-base-second flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-[33.14px] relative h-8"
              alt=""
              src="/vector.svg"
            />
            <div className="self-stretch relative leading-[22px] font-medium inline-block h-[90px] shrink-0">
              Innovative construction, blending moderntech with new
              developments.
            </div>
          </div>
          <div className="flex-1 rounded-roundness-outside-m bg-background-base-second flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-[33.73px] relative h-8"
              alt=""
              src="/vector.svg"
            />
            <div className="self-stretch relative leading-[22px] font-medium inline-block h-[90px] shrink-0">
              Carthage's array of projects spans from residential gems to
              commercial marvels.
            </div>
          </div>
          <div className="flex-1 rounded-roundness-outside-m bg-background-base-second flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-[29.68px] relative h-8"
              alt=""
              src="/vector.svg"
            />
            <div className="self-stretch relative leading-[22px] font-medium inline-block h-[90px] shrink-0">
              Building tomorrow with today's technology -Carthage Development at
              the forefront.
            </div>
          </div>
          <div className="self-stretch w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-32 relative h-8 overflow-hidden shrink-0"
              alt=""
            />
            <div className="self-stretch relative leading-[22px] font-medium">
              Consider big data so that we create actionable insights.
            </div>
          </div>
          <div className="self-stretch w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-32 relative h-8 overflow-hidden shrink-0"
              alt=""
            />
            <div className="self-stretch relative leading-[22px] font-medium">
              Consider big data so that we create actionable insights.
            </div>
          </div>
          <div className="self-stretch w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img
              className="w-32 relative h-8 overflow-hidden shrink-0"
              alt=""
            />
            <div className="self-stretch relative leading-[22px] font-medium">
              Consider big data so that we create actionable insights.
            </div>
          </div>
          <div className="self-stretch w-[280px] rounded-roundness-outside-m bg-background-base-second hidden flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px]">
            <img className="w-32 relative h-8" alt="" />
            <div className="self-stretch relative leading-[22px] font-medium">
              Consider big data so that we create actionable insights.
            </div>
          </div>
        </div>
        <div className="w-[1280px] hidden flex-row items-center justify-center gap-[16px] text-base-white">
          <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px]">
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
              View All
            </div>
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
          </div>
          <div className="rounded-roundness-inside-m bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden hidden flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-text-icons-base-main border-[1px] border-solid border-stroke-base">
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
              Button
            </div>
            <img
              className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
        }}
        className="w-device-desktop bg-background-dark-base-main flex flex-col items-center justify-start py-16 px-padding-desktop-main box-border gap-[48px] text-center text-21xl text-base-white"
      >
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="self-stretch rounded-roundness-outside-m bg-background-dark-base-main flex flex-col items-center justify-center relative gap-[32px]">
            <div className="w-[1216px] absolute !m-[0] top-[calc(50%_-_131px)] left-[calc(50%_-_608px)] h-[262px] overflow-hidden shrink-0 hidden z-[0]">
              <img
                className="absolute top-[calc(50%_-_720px)] left-[calc(50%_-_720px)] w-[1440px] h-[1440px] overflow-hidden"
                alt=""
              />
              <div className="absolute h-[100.27%] w-full top-[-0.14%] right-[0%] bottom-[-0.14%] left-[0%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(29,_29,_30,_0.64),_rgba(20,_21,_21,_0.8))]" />
            </div>
            <div className="self-stretch flex flex-col items-center justify-center z-[1]">
              <div className="self-stretch flex flex-col items-center justify-center">
                <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
                  <div className="self-stretch relative tracking-[-0.01em] leading-[40px]">
                    Explore and Diversify Your Portfolio 
                  </div>
                  <div className="self-stretch relative text-base leading-[24px] text-text-icons-dark-base-second">
                    Explore our latest deals and opportunity fund with our
                    seamless technology.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[16px] z-[2] text-left text-mini">
              <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px]">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Invest Now
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
              <div className="rounded-roundness-inside-m bg-background-dark-base-third shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-text-icons-dark-base-main border-[1px] border-solid border-stroke-dark-base">
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
                <div className="relative tracking-[0.1px] leading-[22px] font-semibold">
                  Log in
                </div>
                <img
                  className="w-5 relative h-5 overflow-hidden shrink-0 hidden"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="self-stretch relative box-border h-px border-t-[1px] border-solid border-stroke-dark-base" />
        <div className="self-stretch flex flex-col items-start justify-start text-left text-base">
          <div className="self-stretch flex flex-row items-end justify-start pt-0 px-0 pb-8 gap-[152px]">
            <div className="w-[247px] flex flex-col items-start justify-start gap-[16px]">
              <b className="w-[198px] relative leading-[24px] inline-block h-[34px] shrink-0">
                Carthage Development
              </b>
              <div className="flex flex-col items-start justify-start text-text-icons-dark-base-second">
                <div className="w-[247px] relative leading-[24px] font-medium inline-block">
                  Full Design Build Construction
                </div>
              </div>
            </div>
            <div className="flex-1 flex flex-row items-start justify-between text-text-icons-dark-base-main">
              <div className="flex flex-col items-start justify-start">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-semibold">
                    Disclaimers
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-semibold">
                    Disclosures
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-semibold">
                    Privacy Policy
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex flex-col items-start justify-start">
                <div className="rounded-8xs overflow-hidden flex flex-row items-center justify-center gap-[4px]">
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                  <div className="relative leading-[24px] font-semibold">
                    Term of Use
                  </div>
                  <img
                    className="w-4 relative h-4 overflow-hidden shrink-0 hidden"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="self-stretch flex flex-row items-center justify-between pt-6 px-0 pb-0 text-text-icons-dark-base-second border-t-[1px] border-solid border-stroke-dark-base">
            <div className="relative leading-[24px] font-medium">
              © 2023 Carthage Develpment. All Rights Reserved.
            </div>
            <div className="flex flex-row items-start justify-start gap-[24px]">
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0"
                alt=""
                src="/social-platforms-logos.svg"
              />
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0"
                alt=""
                src="/social-platforms-logos.svg"
              />
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0"
                alt=""
                src="/social-platforms-logos.svg"
              />
              <img
                className="w-6 relative h-6 overflow-hidden shrink-0"
                alt=""
                src="/social-platforms-logos.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
