import { FunctionComponent } from 'react';



const ArrowsarrowrightIcon1:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/arrowsarrowright.svg" />);
};

export default ArrowsarrowrightIcon1;
