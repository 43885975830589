import { FunctionComponent } from 'react';



const ArrowschevronrightIcon:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/arrowschevronright.svg" />);
};

export default ArrowschevronrightIcon;
