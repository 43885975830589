import { FunctionComponent } from 'react';



const DropdownDarkLinkFalseMediu:FunctionComponent = () => {
  return (
    <div className="relative w-full flex flex-col items-end justify-start text-right text-mini text-text-icons-dark-base-main font-text-m-medium">
      <div className="h-[22px] flex flex-row items-center justify-start gap-[4px]">
        <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
        <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Item 1</div>
        <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/arrowschevrondown.svg" />
      </div>
    </div>);
};

export default DropdownDarkLinkFalseMediu;
