import { FunctionComponent } from 'react';



const CallToActionDark3Desktop:FunctionComponent = () => {
  return (
    <div className="w-device-desktop relative bg-background-dark-base-main overflow-hidden flex flex-col items-center justify-center py-16 px-padding-desktop-main box-border text-center text-13xl text-text-icons-dark-base-main font-text-m-medium">
      <div className="self-stretch rounded-roundness-outside-m bg-text-icons-base-main overflow-hidden flex flex-col items-center justify-center py-10 px-0 relative gap-[32px]">
        <div className="w-[1216px] absolute !m-[0] top-[calc(50%_-_131px)] left-[calc(50%_-_608px)] h-[262px] overflow-hidden shrink-0 hidden z-[0]">
          <img className="absolute top-[calc(50%_-_720px)] left-[calc(50%_-_720px)] w-[1440px] h-[1440px] overflow-hidden" alt="" />
          <div className="absolute h-[100.27%] w-full top-[-0.14%] right-[0%] bottom-[-0.14%] left-[0%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(29,_29,_30,_0.64),_rgba(20,_21,_21,_0.8))]" />
        </div>
        <div className="self-stretch flex flex-col items-center justify-center z-[1]">
          <div className="self-stretch flex flex-col items-center justify-center">
            <div className="self-stretch flex flex-col items-start justify-start gap-[16px]">
              <b className="self-stretch relative tracking-[-0.01em] leading-[40px]">Welcome to your new digital reality</b>
              <div className="self-stretch relative text-base leading-[24px] text-text-icons-dark-base-second">
                <p className="m-0">Let us help you take you from zero to serious business and beyond.</p>
                <p className="m-0">Our no-strings attached free trial lets you test our product today.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-start gap-[16px] z-[2] text-left text-mini text-base-white">
          <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px]">
            <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Sign up</div>
            <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
          </div>
          <div className="rounded-roundness-inside-m bg-background-dark-base-third shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-text-icons-dark-base-main border-[1px] border-solid border-stroke-dark-base">
            <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
            <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Log in</div>
            <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
          </div>
        </div>
      </div>
    </div>);
};

export default CallToActionDark3Desktop;
