import { FunctionComponent } from 'react';



const EssentialscheckboxIcon:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/essentialscheckbox.svg" />);
};

export default EssentialscheckboxIcon;
