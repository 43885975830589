import { FunctionComponent } from 'react';



const ThemeLightVariant:FunctionComponent = () => {
  return (
    <div className="w-full relative rounded-roundness-outside-m bg-background-base-second flex flex-col items-center justify-center p-6 box-border gap-[16px] min-w-[240px] text-center text-mini text-text-icons-base-second font-text-m-medium">
      <img className="w-32 relative h-8 overflow-hidden shrink-0" alt="" src="/company-logos.svg" />
      <div className="self-stretch relative leading-[22px] font-medium">Consider big data so that we create actionable insights.</div>
    </div>);
};

export default ThemeLightVariant;
