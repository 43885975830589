import { FunctionComponent } from 'react';



const BuildingsLandmarksShop2F:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6 object-cover" alt="" src="/buildings--landmarksshop2-fill@2x.png" />);
};

export default BuildingsLandmarksShop2F;
