import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import PlatformInstagramColorWhitIcon from "./pages/PlatformInstagramColorWhitIcon";
import PlatformAppleColorWhiteIcon from "./pages/PlatformAppleColorWhiteIcon";
import PlatformGoogleColorWhiteIcon from "./pages/PlatformGoogleColorWhiteIcon";
import PlatformFacebookColorWhiteIcon from "./pages/PlatformFacebookColorWhiteIcon";
import HierarchyPrimary from "./pages/HierarchyPrimary";
import EssentialsplaceholderIcon from "./pages/EssentialsplaceholderIcon";
import CallToActionDark3Desktop from "./pages/CallToActionDark3Desktop";
import ButtonDarkTertiaryButtonLa from "./pages/ButtonDarkTertiaryButtonLa";
import ArrowsarrowrightIcon from "./pages/ArrowsarrowrightIcon";
import EssentialscheckboxIcon from "./pages/EssentialscheckboxIcon";
import ThemeLightHierarchyPrimary from "./pages/ThemeLightHierarchyPrimary";
import ThemeDarkLayoutCenterBre from "./pages/ThemeDarkLayoutCenterBre";
import Property1DarkProperty from "./pages/Property1DarkProperty";
import ThemeLightHierarchyPrimary1 from "./pages/ThemeLightHierarchyPrimary1";
import ThemeLightHierarchyTertiar from "./pages/ThemeLightHierarchyTertiar";
import ThemeLightVariant from "./pages/ThemeLightVariant";
import LogoinstacartThemeLight from "./pages/LogoinstacartThemeLight";
import LogomediumThemeLight from "./pages/LogomediumThemeLight";
import LogoamazoncomThemeLight from "./pages/LogoamazoncomThemeLight";
import LogodropboxThemeLight from "./pages/LogodropboxThemeLight";
import ThemeLightOrientationCente from "./pages/ThemeLightOrientationCente";
import ThemeLightHierarchySeconda from "./pages/ThemeLightHierarchySeconda";
import ArrowschevronrightIcon from "./pages/ArrowschevronrightIcon";
import EssentialscheckboxIcon1 from "./pages/EssentialscheckboxIcon1";
import ThemeLightHierarchyPrimary2 from "./pages/ThemeLightHierarchyPrimary2";
import ArrowsarrowrightIcon1 from "./pages/ArrowsarrowrightIcon1";
import ThemeLightTypeCircleSize from "./pages/ThemeLightTypeCircleSize";
import BuildingsLandmarksShop2F from "./pages/BuildingsLandmarksShop2F";
import BuildingsLandmarksHouse from "./pages/BuildingsLandmarksHouse";
import BuildingsLandmarksBuilding from "./pages/BuildingsLandmarksBuilding";
import TypeLogoGlypgSizeMedium from "./pages/TypeLogoGlypgSizeMedium";
import StatusOnlineIcon from "./pages/StatusOnlineIcon";
import InteractionsminimizeIcon from "./pages/InteractionsminimizeIcon";
import ThemeLightTypeRoundSize from "./pages/ThemeLightTypeRoundSize";
import ThemeDarkLayoutSideBreak from "./pages/ThemeDarkLayoutSideBreak";
import Property1DarkProperty1 from "./pages/Property1DarkProperty1";
import ThemeLightHierarchyPrimary3 from "./pages/ThemeLightHierarchyPrimary3";
import EssentialsdoubleHeartIcon from "./pages/EssentialsdoubleHeartIcon";
import LabelDarkPillPrimaryLabel from "./pages/LabelDarkPillPrimaryLabel";
import ButtonDarkSecondaryLinkLar from "./pages/ButtonDarkSecondaryLinkLar";
import DropdownDarkLinkFalseMediu from "./pages/DropdownDarkLinkFalseMediu";
import ArrowschevrondownIcon from "./pages/ArrowschevrondownIcon";
import MenusdotMenuIcon from "./pages/MenusdotMenuIcon";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/platforminstagram-colorwhite":
        title = "";
        metaDescription = "";
        break;
      case "/platformapple-colorwhite":
        title = "";
        metaDescription = "";
        break;
      case "/platformgoogle-colorwhite":
        title = "";
        metaDescription = "";
        break;
      case "/platformfacebook-colorwhite":
        title = "";
        metaDescription = "";
        break;
      case "/hierarchyprimary":
        title = "";
        metaDescription = "";
        break;
      case "/essentialsplaceholder":
        title = "";
        metaDescription = "";
        break;
      case "/call-to-actiondark3desktop":
        title = "";
        metaDescription = "";
        break;
      case "/buttondarktertiarybuttonlargedefaultfalse":
        title = "";
        metaDescription = "";
        break;
      case "/arrowsarrowright":
        title = "";
        metaDescription = "";
        break;
      case "/essentialscheckbox":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-hierarchyprimary-typebutton-sizelarge-statedefault-destructivefalse":
        title = "";
        metaDescription = "";
        break;
      case "/themedark-layoutcenter-breakpointdesktop":
        title = "";
        metaDescription = "";
        break;
      case "/property-1dark-property-21":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-hierarchyprimary":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-hierarchytertiary-typebutton-sizelarge-statedefault-destructivefalse":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-variant1":
        title = "";
        metaDescription = "";
        break;
      case "/logoinstacart-themelight":
        title = "";
        metaDescription = "";
        break;
      case "/logomedium-themelight":
        title = "";
        metaDescription = "";
        break;
      case "/logoamazoncom-themelight":
        title = "";
        metaDescription = "";
        break;
      case "/logodropbox-themelight":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-orientationcenter-hierarchydefault-breakpointdesktop":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-hierarchysecondary-typebutton-sizelarge-statedefault-destructivefalse":
        title = "";
        metaDescription = "";
        break;
      case "/arrowschevronright":
        title = "";
        metaDescription = "";
        break;
      case "/essentialscheckbox1":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-hierarchyprimary-typelink-sizesmall-statedefault-destructivefalse":
        title = "";
        metaDescription = "";
        break;
      case "/arrowsarrowright1":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-typecircle-sizemedium-hierarchysecondary":
        title = "";
        metaDescription = "";
        break;
      case "/buildings-landmarksshop2-fill":
        title = "";
        metaDescription = "";
        break;
      case "/buildings-landmarkshouse1-fill":
        title = "";
        metaDescription = "";
        break;
      case "/buildings-landmarksbuilding1-fill":
        title = "";
        metaDescription = "";
        break;
      case "/typelogo-glypg-sizemedium-statedefault":
        title = "";
        metaDescription = "";
        break;
      case "/statusonline":
        title = "";
        metaDescription = "";
        break;
      case "/interactionsminimize":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-typeround-sizemedium-statechecked-stylefill":
        title = "";
        metaDescription = "";
        break;
      case "/themedark-layoutside-breakpointdesktop":
        title = "";
        metaDescription = "";
        break;
      case "/property-1dark-property-22":
        title = "";
        metaDescription = "";
        break;
      case "/themelight-hierarchyprimary-typebutton-sizexlarge-statedefault-destructivefalse":
        title = "";
        metaDescription = "";
        break;
      case "/essentialsdouble-heart":
        title = "";
        metaDescription = "";
        break;
      case "/labeldarkpillprimarylabelsmallprimary":
        title = "";
        metaDescription = "";
        break;
      case "/buttondarksecondarylinklargedefaultfalse":
        title = "";
        metaDescription = "";
        break;
      case "/dropdowndarklinkfalsemediumon":
        title = "";
        metaDescription = "";
        break;
      case "/arrowschevrondown":
        title = "";
        metaDescription = "";
        break;
      case "/menusdot-menu":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route
        path="/platforminstagram-colorwhite"
        element={<PlatformInstagramColorWhitIcon />}
      />
      <Route
        path="/platformapple-colorwhite"
        element={<PlatformAppleColorWhiteIcon />}
      />
      <Route
        path="/platformgoogle-colorwhite"
        element={<PlatformGoogleColorWhiteIcon />}
      />
      <Route
        path="/platformfacebook-colorwhite"
        element={<PlatformFacebookColorWhiteIcon />}
      />
      <Route path="/hierarchyprimary" element={<HierarchyPrimary />} />
      <Route
        path="/essentialsplaceholder"
        element={<EssentialsplaceholderIcon />}
      />
      <Route
        path="/call-to-actiondark3desktop"
        element={<CallToActionDark3Desktop />}
      />
      <Route
        path="/buttondarktertiarybuttonlargedefaultfalse"
        element={<ButtonDarkTertiaryButtonLa />}
      />
      <Route path="/arrowsarrowright" element={<ArrowsarrowrightIcon />} />
      <Route path="/essentialscheckbox" element={<EssentialscheckboxIcon />} />
      <Route
        path="/themelight-hierarchyprimary-typebutton-sizelarge-statedefault-destructivefalse"
        element={<ThemeLightHierarchyPrimary />}
      />
      <Route
        path="/themedark-layoutcenter-breakpointdesktop"
        element={<ThemeDarkLayoutCenterBre />}
      />
      <Route
        path="/property-1dark-property-21"
        element={<Property1DarkProperty />}
      />
      <Route
        path="/themelight-hierarchyprimary"
        element={<ThemeLightHierarchyPrimary1 />}
      />
      <Route
        path="/themelight-hierarchytertiary-typebutton-sizelarge-statedefault-destructivefalse"
        element={<ThemeLightHierarchyTertiar />}
      />
      <Route path="/themelight-variant1" element={<ThemeLightVariant />} />
      <Route
        path="/logoinstacart-themelight"
        element={<LogoinstacartThemeLight />}
      />
      <Route path="/logomedium-themelight" element={<LogomediumThemeLight />} />
      <Route
        path="/logoamazoncom-themelight"
        element={<LogoamazoncomThemeLight />}
      />
      <Route
        path="/logodropbox-themelight"
        element={<LogodropboxThemeLight />}
      />
      <Route
        path="/themelight-orientationcenter-hierarchydefault-breakpointdesktop"
        element={<ThemeLightOrientationCente />}
      />
      <Route
        path="/themelight-hierarchysecondary-typebutton-sizelarge-statedefault-destructivefalse"
        element={<ThemeLightHierarchySeconda />}
      />
      <Route path="/arrowschevronright" element={<ArrowschevronrightIcon />} />
      <Route
        path="/essentialscheckbox1"
        element={<EssentialscheckboxIcon1 />}
      />
      <Route
        path="/themelight-hierarchyprimary-typelink-sizesmall-statedefault-destructivefalse"
        element={<ThemeLightHierarchyPrimary2 />}
      />
      <Route path="/arrowsarrowright1" element={<ArrowsarrowrightIcon1 />} />
      <Route
        path="/themelight-typecircle-sizemedium-hierarchysecondary"
        element={<ThemeLightTypeCircleSize />}
      />
      <Route
        path="/buildings-landmarksshop2-fill"
        element={<BuildingsLandmarksShop2F />}
      />
      <Route
        path="/buildings-landmarkshouse1-fill"
        element={<BuildingsLandmarksHouse />}
      />
      <Route
        path="/buildings-landmarksbuilding1-fill"
        element={<BuildingsLandmarksBuilding />}
      />
      <Route
        path="/typelogo-glypg-sizemedium-statedefault"
        element={<TypeLogoGlypgSizeMedium />}
      />
      <Route path="/statusonline" element={<StatusOnlineIcon />} />
      <Route
        path="/interactionsminimize"
        element={<InteractionsminimizeIcon />}
      />
      <Route
        path="/themelight-typeround-sizemedium-statechecked-stylefill"
        element={<ThemeLightTypeRoundSize />}
      />
      <Route
        path="/themedark-layoutside-breakpointdesktop"
        element={<ThemeDarkLayoutSideBreak />}
      />
      <Route
        path="/property-1dark-property-22"
        element={<Property1DarkProperty1 />}
      />
      <Route
        path="/themelight-hierarchyprimary-typebutton-sizexlarge-statedefault-destructivefalse"
        element={<ThemeLightHierarchyPrimary3 />}
      />
      <Route
        path="/essentialsdouble-heart"
        element={<EssentialsdoubleHeartIcon />}
      />
      <Route
        path="/labeldarkpillprimarylabelsmallprimary"
        element={<LabelDarkPillPrimaryLabel />}
      />
      <Route
        path="/buttondarksecondarylinklargedefaultfalse"
        element={<ButtonDarkSecondaryLinkLar />}
      />
      <Route
        path="/dropdowndarklinkfalsemediumon"
        element={<DropdownDarkLinkFalseMediu />}
      />
      <Route path="/arrowschevrondown" element={<ArrowschevrondownIcon />} />
      <Route path="/menusdot-menu" element={<MenusdotMenuIcon />} />
    </Routes>
  );
}
export default App;
