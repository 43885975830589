import { FunctionComponent } from 'react';



const LogoamazoncomThemeLight:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-8" alt="" src="/logoamazoncom-themelight.svg" />);
};

export default LogoamazoncomThemeLight;
