import { FunctionComponent } from 'react';



const ThemeLightHierarchyPrimary1:FunctionComponent = () => {
  return (
    <div className="w-full relative flex flex-row items-center justify-center py-0 px-8 box-border gap-[16px] text-left text-mini text-base-white font-text-m-medium">
      <div className="rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px]">
        <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
        <div className="relative tracking-[0.1px] leading-[22px] font-semibold">View All</div>
        <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
      </div>
      <div className="rounded-roundness-inside-m bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] overflow-hidden hidden flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-text-icons-base-main border-[1px] border-solid border-stroke-base">
        <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
        <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Button</div>
        <img className="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="" />
      </div>
    </div>);
};

export default ThemeLightHierarchyPrimary1;
