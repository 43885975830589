import { FunctionComponent } from 'react';



const ThemeLightTypeCircleSize:FunctionComponent = () => {
  return (
    <div className="relative rounded-roundness-inside-l1 bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border w-full flex flex-row items-center justify-center p-[10.285714149475098px] border-[0.9px] border-solid border-stroke-base">
      <img className="w-[27.43px] relative h-[27.43px]" alt="" src="/icon.svg" />
    </div>);
};

export default ThemeLightTypeCircleSize;
