import { FunctionComponent } from 'react';



const HierarchyPrimary:FunctionComponent = () => {
  return (
    <div className="relative rounded-8xs w-full overflow-hidden flex flex-row items-center justify-center gap-[4px] text-left text-base text-text-icons-base-main font-text-m-medium">
      <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="/essentialsplaceholder.svg" />
      <div className="relative leading-[24px] font-semibold">Features</div>
      <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="/essentialsplaceholder.svg" />
    </div>);
};

export default HierarchyPrimary;
