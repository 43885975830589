import { FunctionComponent } from 'react';



const ThemeLightOrientationCente:FunctionComponent = () => {
  return (
    <div className="w-full relative flex flex-col items-center justify-center max-w-[1280px] text-left text-sm text-background-primary-main font-text-m-medium">
      <div className="w-[800px] flex flex-col items-center justify-center gap-[8px]">
        <div className="w-[148px] relative leading-[20px] font-semibold hidden">1% OF THE INDUSTRY</div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[16px] text-center text-13xl text-text-icons-base-main">
          <b className="self-stretch relative tracking-[-0.01em] leading-[40px]">This is a headline</b>
          <div className="self-stretch relative text-lg leading-[26px] text-text-icons-base-second">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate ex et mauris semper, quis pharetra nulla mollis. Sed sodales ante libero, sit amet efficitur est imperdiet at.</div>
        </div>
      </div>
    </div>);
};

export default ThemeLightOrientationCente;
