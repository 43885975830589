import { FunctionComponent } from 'react';



const ThemeLightTypeRoundSize:FunctionComponent = () => {
  return (
    <div className="relative w-full flex flex-row items-start justify-start gap-[12px] text-left text-base text-text-icons-base-main font-text-m-medium">
      <div className="flex flex-row items-center justify-center py-0.5 px-0">
        <img className="w-5 relative rounded-roundness-inside-l1 h-5" alt="" src="/checkbox.svg" />
      </div>
      <div className="w-[137px] flex flex-col items-start justify-start gap-[2px]">
        <div className="self-stretch relative leading-[24px] font-semibold">Checkbox Title</div>
        <div className="self-stretch relative text-sm leading-[20px] text-text-icons-base-second inline-block h-6 shrink-0">Supporting Text</div>
      </div>
    </div>);
};

export default ThemeLightTypeRoundSize;
