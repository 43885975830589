import { FunctionComponent } from 'react';



const ThemeLightHierarchyTertiar:FunctionComponent = () => {
  return (
    <div className="relative rounded-roundness-inside-m bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] box-border w-full overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 gap-[6px] text-left text-mini text-text-icons-base-main font-text-m-medium border-[1px] border-solid border-stroke-base">
      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/essentialscheckbox.svg" />
      <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Button</div>
      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/arrowsarrowright.svg" />
    </div>);
};

export default ThemeLightHierarchyTertiar;
