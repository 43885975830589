import { FunctionComponent } from 'react';



const LogomediumThemeLight:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-8" alt="" src="/logomedium-themelight.svg" />);
};

export default LogomediumThemeLight;
