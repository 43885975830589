import { FunctionComponent } from 'react';



const BuildingsLandmarksBuilding:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/buildings--landmarksbuilding1-fill.svg" />);
};

export default BuildingsLandmarksBuilding;
