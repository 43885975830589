import { FunctionComponent } from 'react';



const ThemeLightHierarchyPrimary:FunctionComponent = () => {
  return (
    <div className="relative rounded-roundness-inside-m bg-background-primary-main shadow-[0px_1px_2px_rgba(16,_24,_40,_0.04)] w-full overflow-hidden flex flex-row items-center justify-center py-main-system-12 px-main-system-20 box-border gap-[6px] text-left text-mini text-base-white font-text-m-medium">
      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/essentialscheckbox.svg" />
      <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Button</div>
      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/arrowsarrowright.svg" />
    </div>);
};

export default ThemeLightHierarchyPrimary;
