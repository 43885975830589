import { FunctionComponent } from 'react';



const Property1DarkProperty:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-[1920px]" alt="" src="/property-1dark-property-21.svg" />);
};

export default Property1DarkProperty;
