import { FunctionComponent } from 'react';



const LabelDarkPillPrimaryLabel:FunctionComponent = () => {
  return (
    <div className="relative rounded-roundness-inside-l1 bg-background-primary-main w-full flex flex-row items-center justify-start py-main-system-2 px-main-system-81 box-border text-left text-sm text-base-white font-text-m-medium">
      <div className="relative leading-[20px] font-medium">Label</div>
    </div>);
};

export default LabelDarkPillPrimaryLabel;
