import { FunctionComponent } from 'react';



const ButtonDarkSecondaryLinkLar:FunctionComponent = () => {
  return (
    <div className="relative rounded-roundness-inside-m w-full overflow-hidden flex flex-row items-center justify-center gap-[6px] text-left text-mini text-text-icons-dark-base-main font-text-m-medium">
      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/essentialscheckbox.svg" />
      <div className="relative tracking-[0.1px] leading-[22px] font-semibold">Button</div>
      <img className="w-5 relative h-5 overflow-hidden shrink-0" alt="" src="/arrowsarrowright.svg" />
    </div>);
};

export default ButtonDarkSecondaryLinkLar;
