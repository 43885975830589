import { FunctionComponent } from 'react';



const EssentialsplaceholderIcon:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/essentialsplaceholder.svg" />);
};

export default EssentialsplaceholderIcon;
