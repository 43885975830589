import { FunctionComponent } from 'react';



const PlatformInstagramColorWhitIcon:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/platforminstagram-colorwhite.svg" />);
};

export default PlatformInstagramColorWhitIcon;
