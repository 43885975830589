import { FunctionComponent } from 'react';



const TypeLogoGlypgSizeMedium:FunctionComponent = () => {
  return (
    <div className="w-full relative rounded-31xl h-10 flex flex-row items-center justify-center bg-[url('/public/avatar1@3x.png')] bg-cover bg-no-repeat bg-[top]">
      <img className="w-3.5 absolute !m-[0] top-[28px] left-[28px] h-3.5 z-[0]" alt="" src="/status-icon.svg" />
    </div>);
};

export default TypeLogoGlypgSizeMedium;
