import { FunctionComponent } from 'react';



const LogoinstacartThemeLight:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-8" alt="" src="/logoinstacart-themelight.svg" />);
};

export default LogoinstacartThemeLight;
