import { FunctionComponent } from 'react';



const PlatformFacebookColorWhiteIcon:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/platformfacebook-colorwhite.svg" />);
};

export default PlatformFacebookColorWhiteIcon;
