import { FunctionComponent } from 'react';



const ThemeLightHierarchyPrimary2:FunctionComponent = () => {
  return (
    <div className="relative rounded-roundness-inside-m1 w-full overflow-hidden flex flex-row items-center justify-center gap-[6px] text-left text-sm text-background-primary-main font-text-m-medium">
      <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="/essentialscheckbox.svg" />
      <div className="relative tracking-[0.1px] leading-[20px] font-semibold">Button</div>
      <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src="/arrowsarrowright.svg" />
    </div>);
};

export default ThemeLightHierarchyPrimary2;
