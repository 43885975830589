import { FunctionComponent } from 'react';



const ThemeDarkLayoutCenterBre:FunctionComponent = () => {
  return (
    <div className="w-device-desktop relative h-[740px] overflow-hidden">
      <img className="absolute top-[calc(50%_-_720px)] left-[calc(50%_-_720px)] w-[1440px] h-[1440px] overflow-hidden" alt="" src="/background-pattern.svg" />
      <div className="absolute h-[100.27%] w-full top-[-0.14%] right-[0%] bottom-[-0.14%] left-[0%] [background:radial-gradient(50%_50%_at_50%_50%,_rgba(29,_29,_30,_0.16),_rgba(20,_21,_21,_0.72))]" />
    </div>);
};

export default ThemeDarkLayoutCenterBre;
