import { FunctionComponent } from 'react';



const StatusOnlineIcon:FunctionComponent = () => {
  return (
    <img className="w-full relative max-w-full overflow-hidden h-6" alt="" src="/statusonline.svg" />);
};

export default StatusOnlineIcon;
